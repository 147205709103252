/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.navbar-toggler').click(function() {
          $('#navbar').toggleClass('show');
          $(this).toggleClass('collapsed');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // // JavaScript to be fired on the home page
        // $('.link-display').hover(function(){
        //   $('.link-display').not(this).toggleClass('toggle');
        // });
        // // detect touch 
        if (!("ontouchstart" in document.documentElement)) {
          document.documentElement.className += " no-touch";
        }
        $('.link-display').on('touchstart touchend', function(e) {
          $(this).toggleClass('over');  
          $('.link-display').not(this).toggleClass('toggle');       
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single': {
      init: function() {
        // JavaScript to be fired on the photographer page
        
        // Lightbox gallery
        $('[data-fancybox="gallery"]').fancybox({

          mobile : {
            margin : [45, 0],
          },
          buttons : [
            'thumbs',
            'close',
          ],
          margin : [50, 108],
          idleTime: false,
          protect : true,
          arrows : true,
          loop: true,
          transitionEffect : "slide",
          animationEffect : "fade",
          animationDuration : 350,
          clickContent    : false,
          btnTpl : {
            close : '<button data-fancybox-close class="fancybox-button fancybox-button--close navbar-light" title="{{CLOSE}}">' +
                      '<span class="fancy-box-icon fancy-box-icon--close"></span>' +
                    '</button>',
            arrowLeft : '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
                        '<span class="navbar-toggler-icon arrow-left-icon"></span>' +
                      '</button>',
            arrowRight : '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
                        '<span class="navbar-toggler-icon arrow-right-icon"></span>' +
                    '</button>',
            thumbs : '<button data-fancybox-thumbs class="fancybox-button fancybox-button--thumbs navbar-light" title="{{THUMBS}}">' +
                      '<span class="fancy-box-icon fancy-box-icon--thumbs "></span>' +
                    '</button>',
            },
          baseTpl :
          '<div class="fancybox-container" role="dialog" tabindex="-1">' +
              '<div class="fancybox-bg"></div>' +
              '<div class="fancybox-inner">' +
                  '<div class="fancybox-infobar">' +
                      '<span data-fancybox-index></span>/<span data-fancybox-count></span>' +
                  '</div>' +
                  '<div class="fancybox-toolbar">{{buttons}}</div>' +
                  '<div class="fancybox-navigation">{{arrows}}</div>' +
                  '<div class="fancybox-stage"></div>' +
                  '<div class="fancybox-caption-wrap"><div class="fancybox-caption"></div></div>' +
              '</div>' +
          '</div>'

        });

        // Masonry

        $('.masonry').imagesLoaded(function () {
          var Shuffle = window.Shuffle;
          var element = document.querySelector('.masonry');

          var shuffleInstance = new Shuffle(element, {
            itemSelector: '.masonry-panel',
            sizer: '.my-sizer-element',
            speed: 0,
            initialSort: 'photo'
          });

          Shuffle.ShuffleItem.Scale.HIDDEN = 0.999;


          $('.filters-group button').on('click', function (e) {
            e.preventDefault();
            $('.filters-group button').removeClass('text-dark');
            $(this).addClass('text-dark');
            var keyword = $(this).attr('data-group');
            shuffleInstance.filter(keyword);
          });

          $(".filter-button:first-child").trigger("click");

        });

        // $(".filter-button:first-child").before("<button class='btn heading-display-subtitle d-inline p-0 mr-2 mr-md-3 shadow-none text-muted text-dark' data-group='Overview'>Overview</button>");

        

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
